var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"Force-Storage"}},[_vm._m(0),_c('div',{staticClass:"bottom"},[(_vm.list.datas && 0 < _vm.list.datas.length)?[_c('a-row',_vm._l((_vm.list.datas),function(item,i){return _c('a-col',{key:i,class:`item-` + i,attrs:{"span":24}},[_c('a-button',[_c('div',[_c('div',{staticClass:"top"},[_c('p',{style:({
                    display: 'flex',
                    'flex-wrap': 'wrap',
                  })},[_c('span',{style:({
                      color: 'inherit',
                      'font-size': '1.2em !important',
                    })},[_vm._v(" "+_vm._s(item.name.split(" ")[0])+" ")]),_c('span',{style:({ color: 'inherit' })},[_vm._v(" "+_vm._s(item.name.split(item.name.split(" ")[0])[1])+" ")])]),_c('p',[_vm._v(_vm._s(item.status))])]),_c('div',{staticClass:"bottom"},[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('span',[_vm._v("结算周期: ")]),_c('span',[_vm._v(_vm._s(item.settlement_cycle))])]),_c('a-col',{attrs:{"span":24}},[_c('span',[_vm._v("合约期限: ")]),_c('span',[_vm._v(_vm._s(item.contract_term))])]),_c('a-col',{attrs:{"span":24}},[_c('span',[_vm._v("剩余天数: ")]),_c('span',[_vm._v(_vm._s(item.days_remaining))])]),_c('a-col',{attrs:{"span":24}},[_c('span',[_vm._v("结束日期: ")]),_c('span',[_vm._v(_vm._s(item.expire_date))])]),_c('a-col',{attrs:{"span":24}},[_c('span',[_vm._v("技术服务费: ")]),_c('span',[_vm._v(_vm._s(item.cost))])]),_c('a-col',{attrs:{"span":24}},[_c('span',[_vm._v(_vm._s(item.title)+":")]),_c('span',[_vm._v(_vm._s(item.quantity))])]),_c('a-col',{attrs:{"span":24}},[_c('span',[_vm._v("当次收益: ")]),_c('span',[_vm._v(_vm._s(item.daily_revenue))])]),_c('a-col',{attrs:{"span":24}},[_c('span',[_vm._v(" 累计收益 "),_c('a-tooltip',{attrs:{"arrow-point-at-center":"","placement":"topLeft"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("未扣除技术服务费")])]),_c('a-icon',{attrs:{"type":"info-circle"}})],2),_vm._v(" : ")],1),_c('span',[_vm._v(_vm._s(item.cumulative_revenue))])]),(item.total_gas_burn)?_c('a-col',{attrs:{"span":24}},[_c('span',[_vm._v("累计Gas消耗: ")]),_c('span',[_vm._v(_vm._s(item.total_gas_burn))])]):_vm._e(),(item.total_sector_pledge)?_c('a-col',{attrs:{"span":24}},[_c('span',[_vm._v("累计扇区质押: ")]),_c('span',[_vm._v(_vm._s(item.total_sector_pledge))])]):_vm._e()],1)],1)])])],1)}),1)]:[_c('a-empty')]],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"left title components"},[_vm._v("我的存储器")])])
}]

export { render, staticRenderFns }