<template>
  <div id="Force-Storage">
    <div class="top">
      <div class="left title components">我的存储器</div>
    </div>
    <div class="bottom">
      <template v-if="list.datas && 0 < list.datas.length">
        <a-row>
          <a-col
            v-for="(item, i) in list.datas"
            :class="`item-` + i"
            :span="24"
            :key="i"
          >
            <a-button>
              <div>
                <div class="top">
                  <p
                    :style="{
                      display: 'flex',
                      'flex-wrap': 'wrap',
                    }"
                  >
                    <span
                      :style="{
                        color: 'inherit',
                        'font-size': '1.2em !important',
                      }"
                    >
                      {{ item.name.split(" ")[0] }}
                    </span>
                    <span :style="{ color: 'inherit' }">
                      {{ item.name.split(item.name.split(" ")[0])[1] }}
                    </span>
                  </p>
                  <p>{{ item.status }}</p>
                </div>
                <div class="bottom">
                  <a-row>
                    <a-col :span="24">
                      <span>结算周期: </span>
                      <span>{{ item.settlement_cycle }}</span>
                    </a-col>
                    <a-col :span="24">
                      <span>合约期限: </span>
                      <span>{{ item.contract_term }}</span>
                    </a-col>
                    <a-col :span="24">
                      <span>剩余天数: </span>
                      <span>{{ item.days_remaining }}</span>
                    </a-col>
                    <a-col :span="24">
                      <span>结束日期: </span>
                      <span>{{ item.expire_date }}</span>
                    </a-col>
                    <a-col :span="24">
                      <span>技术服务费: </span>
                      <span>{{ item.cost }}</span>
                    </a-col>
                    <a-col :span="24">
                      <span>{{ item.title }}:</span>
                      <span>{{ item.quantity }}</span>
                    </a-col>
                    <a-col :span="24">
                      <span>当次收益: </span>
                      <span>{{ item.daily_revenue }}</span>
                    </a-col>
                    <a-col :span="24">
                      <span>
                        累计收益
                        <a-tooltip arrow-point-at-center placement="topLeft">
                          <template slot="title">
                            <span>未扣除技术服务费</span>
                          </template>
                          <a-icon type="info-circle" />
                        </a-tooltip>
                        :
                      </span>
                      <span>{{ item.cumulative_revenue }}</span>
                    </a-col>
                    <a-col :span="24" v-if="item.total_gas_burn">
                      <span>累计Gas消耗: </span>
                      <span>{{ item.total_gas_burn }}</span>
                    </a-col>
                    <a-col :span="24" v-if="item.total_sector_pledge">
                      <span>累计扇区质押: </span>
                      <span>{{ item.total_sector_pledge }}</span>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </a-button>
          </a-col>
        </a-row>
      </template>
      <template v-else>
        <a-empty />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Force-Storage",
  // 数据
  data() {
    return {
      list: {
        datas: [],
        unfoldShow: false,
      },
    };
  },
  // 方法
  methods: {
    async getClustersList() {
      await this.$axios.get("personal-center/clusters").then((res) => {
        if (res.code === 0) this.list.datas = res.data;
      });
    },
  },
  created() {
    this.getClustersList();
  },
};
</script>

<!-- 添加"scoped"属性，以限制CSS仅对该组件 -->
<style lang="less" scoped>
#Force-Storage {
  > .top {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;

    > .left {
      > span {
        //: 0.6em;
        color: #dcdcdc;
      }
    }

    > .right {
      //: 0.25rem;
      color: #565e9a;
    }
  }

  > .bottom {
    > .ant-row {
      > .ant-col {
        box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);
        // padding: 0.2rem;
        margin-bottom: 0.2rem;

        > .ant-btn {
          border-radius: 0.2rem;
          background-color: #3d447b;

          > * {
            padding: 0.2rem;

            * {
              text-align: left;
            }

            > .top {
              display: flex;
              justify-content: space-between;

              > p:first-child:not(:last-child) {
                color: rgba(221, 63, 88, 1);
                //: 1.2em;
              }

              > p:last-child:not(:first-child) {
                color: rgb(170, 170, 170);
              }
            }

            > .bottom {
              > .ant-row > .ant-col {
                line-height: 2em;
                display: flex;
                justify-content: space-between;

                > span {
                  &:last-child {
                    text-align: right;
                  }
                }

                > span:first-child {
                  color: #ccc;
                }

                &:last-child {
                  //: 1.1em;
                }
              }
            }
          }
        }
      }

      > .ant-btn {
        height: 2em;
        line-height: 2em;
        background-color: #3d447b;

        &:focus {
          background-color: #3d447b;
        }
      }
    }
  }
}
</style>
